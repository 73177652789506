<template>
  <div class="w-3/12 bg-gray-200 zoom-right-enter relative min-w-425" style="height: calc(100vh - 6rem)">
    <div 
      @click="close"
      class="close absolute top-5 right-7 cursor-pointer"
    >
      <img :src="require('../../assets/icons/close-icon.svg')" alt="">
    </div>
    <tabs 
      class="videoDetailsTabs font-mono mt-4" style="height: calc(100% - 7rem)"
      :show.sync="activeTab"
      :keep-alive="true"
    >
      <tab title="General">
        <div v-if="video" class="tab-wrap h-full">
          <div class="mx-7 general-tab-inner">
            <p class="text-base font-light font-grey-darker font-sans mb-2"> Video <span class="font-mono text-sm bg-gray-100	rounded px-2">{{ video.video_id }}</span> </p>
            <h4 class="text-xl font-grey-dark font-sans leading-6 mb-4"> {{ video.title }} </h4>
            <p class="text-base font-light font-grey-darker font-sans mb-2"> Description </p>
            <p class="font-grey-dark font-sans text-base leading-6 mb-4 max-h-24 overflow-y-scroll"> {{ video.description }} </p>
            <p class="text-base font-light font-grey-darker font-sans mb-2"> Publication Date </p>
            <p class="font-grey-dark font-sans text-base leading-6 mb-4"> 
              {{ $moment(video.published_at).format('D') }} {{ $moment(video.published_at).format('MMMM YYYY') }}
            </p>
            <p class="text-base font-light font-grey-darker font-sans mb-2"> Extensions </p>
            
            <p class="flex font-grey-dark font-sans text-base leading-5 py-1.5 border-t border-b border-dotted border-grey-light"> 
              <img :src="require('../../assets/icons/save.svg')" alt="" class="mr-3.5 px-1"> Save Video File 
            </p>
            <p class="flex font-grey-dark font-sans text-base leading-5 py-1.5 border-b border-dotted border-grey-light"> 
              <span class="mr-5 ml-1 w-5 h-5 rounded-full base-bg-checkbox"></span> Analyze Video 
            </p>
          </div>
          <div class="border-t border-grey-light mb-4 bottom-0 w-full">
            <div class="mx-7">
              <p class="text-base font-light font-grey-darker font-sans mb-2 mt-3"> Save Transcript Line </p>
              <div class="flex items-center pt-3.5 pl-6 pb-5 pr-4 border rounded-sm border-grey-darker">
                <multiselect 
                  v-model="value" 
                  :options="selectOptions"
                  :searchable="false"
                  selectLabel=""
                  selectedLabel=""
                  deselectLabel=""
                  placeholder="Pick a value"
                  class="w-72 mr-4 bg-grey font-sans transcriptSelect border border-grey-light"
                >
                  <template slot="option" slot-scope="props">
                    <div class="option__desc font-sans text-base font-light py-1 mx-4 bg-transparent border-b border-dotted border-grey-light">
                      {{ props.option }}
                    </div>
                  </template>
                  <template slot="afterList">
                    <router-link 
                      to="/settings/labels?addNewLabel=true"
                      class="flex option__desc font-sans text-black text-xs py-1.5 px-4 bg-white cursor-pointer"
                    >
                      <img :src="require('../../assets/icons/label.svg')" alt="" class="mr-2">
                      Add a new label
                    </router-link>
                  </template>
                </multiselect>
                <button class="font-sans text-xs base-bg rounded-3xl w-24 h-7"> Save </button>
              </div>
            </div>
          </div>
        </div>
        <raditubeLogo v-else class="h-full"/>
      </tab>

      <tab title="Transcripts">
        <template v-if="transcription">
          <div class="mx-7 h-full overflow-auto">
            <p class="text-base font-light font-grey-darker font-sans mb-2"> Full Transcript </p>
            <span 
              v-for="item_transcription in transcription"
              :key="item_transcription._id"
              class="font-grey-dark font-sans text-base leading-6"
            >
              <openVideoOnTime 
                :video="video" 
                :time="item_transcription._source.start"
              />
              <span> {{ item_transcription._source.line }} </span>
            </span>
          </div>
        </template>
        <raditubeLogo v-else class="h-full"/>
      </tab>

      <tab title="Notes">
        <div class="tab-wrap h-full">
          <template v-if="notes">
            <div class="mx-7 notes-tab-inner w-full">
              <p class="text-base font-light font-grey-darker font-sans mb-2"> Notes </p>
              <template v-if="notes.length">
                <span 
                  v-for="note in notes"
                  :key="note.type"
                  class="font-grey-dark font-sans text-base leading-6"
                >
                  <span 
                    v-for="(message, index) in note.description"
                    :key="`${index}message`"
                  >

                    <openVideoOnTime 
                      v-if="message.time"
                      :video="video" 
                      :time="message.text"
                    /> 
                    <span v-else> {{ message.text }} </span>
                  </span>
                  <p class="base-font-gray-light font-mono text-sm leading-6 py-2 mb-2 border-b border-dotted border-grey-light"> 
                    {{ note.name }}, {{ $moment.unix(note.date).format('DD/MM/YYYY HH:MM') }} 
                  </p>
                </span>
              </template>
              <template v-else>
                <div class="flex flex-wrap items-center no-notes-wrap">
                  <div class="w-full flex flex-wrap justify-center">
                    <img 
                      :src="require('../../assets/icons/pen-edit.svg')" 
                      alt=""
                      class="mb-8"
                    >
                    <p class="text-base base-font-gray-light-900 w-full text-center">This video does not</p>
                    <p class="text-base base-font-gray-light-900 w-full text-center">contain any notes</p>
                  </div>
                </div>
              </template>
            </div>
          </template>

          <raditubeLogo v-else  class="w-full notes-tab-inner"/>

          <div class="px-7 pt-3 border-t border-grey-light mb-4 bottom-0 w-full">
            <div class="flex flex-wrap items-center justify-between mb-4"> 
              <span class="text-base font-light font-grey-darker font-sans">Write a Note</span>
              <div class="flex">
                <div class="relative private-note">
                  <div 
                    class="p-0.5 mx-1 border rounded-full border-grey-light-700 cursor-pointer"
                    @click="setNoteType('private')"
                    :class="{
                      'base-bg purple-text': noteType == 'private'
                    }"
                  >
                    <triangleInShield/>
                  </div>
                  <p 
                    class="tooltip-text hidden absolute tooltipY -left-10 whitespace-nowrap text-base text-black font-sansLight bg-grey border border-grey-lighter rounded px-2 z-10"
                  >
                    Private Note
                  </p>
                  <span class="tooltip-arrow hidden w-4 h-4 bg-grey absolute -top-5 left-1/2 -ml-2"></span>
                </div>
                <div class="relative team-note">
                  <div 
                    class="p-0.5 mx-1 border rounded-full border-grey-light-700"
                    :class="{
                      'base-bg purple-text': noteType != '' && noteType != 'private'
                    }"
                  >
                    <circlesOnCircle/>
                  </div>
                  <div class="team-tooltip tooltip-text hidden bottom-full absolute right-0 z-10">
                    <ul class="bg-grey border border-grey-lighter rounded px-4 mb-3">
                      <li 
                        v-for="(team, index) in teamsList"
                        :key="team.id"
                        class="py-1 whitespace-nowrap text-base text-black font-sansLight cursor-pointer"
                        :class="{
                          'border-b border-dashed border-grey-lighter': (index + 1) < teamsList.length,
                          'font-bold': noteType == team.teamName
                        }"
                        @click="setNoteType(team)"
                      >
                        {{ team.teamName }}
                      </li>
                    </ul>
                  </div>
                  <span class="tooltip-arrow hidden w-4 h-4 bg-grey absolute -top-5 left-1/2 -ml-2"></span>
                </div>
              </div>
            </div>
            <div class="relative">
              <ul 
                v-if="showUsersList"
                class="absolute bottom-full base-bg-darker-100 w-full"
              >
                <li 
                  v-for="user in localUsers"
                  :key="user.user_id"
                  class="py-1 px-3 text-sm border-b border-dotted border-grey-light cursor-pointer"
                  @click="addNameToNoteText(user.name)"
                >
                  {{ user.name }} ({{ user.email }})
                </li>
              </ul>
              <vue-editor
                id="notesEditor"
                ref="notesEditor"
                v-model="noteText"
                @text-change="noteAdding"
                class="w-full base-bg-darker-100 rounded cursor-pointer border rounded-sm border-grey-lighter outline-none notesEditor"
              >
              </vue-editor>
            </div>
            <div class="flex flex-wrap justify-end items-center mt-6">
              <span class="font-sans text-sm underline base-font-gray-light mr-4 cursor-pointer"> note taking tips </span>
              <button 
                class="flex justify-between items-center base-bg rounded-3xl px-2 py-1"
                @click="saveNote"
                :disabled="loading"
                :class="{
                  'disabled:opacity-50': loading
                }"
              > 
                <span class="font-sans text-xs">Submit</span> 
                <Loader 
                  v-if="loading"
                  :animation="true"
                  classes="w-4 h-4 ml-3"
                />
              </button>
            </div>
          </div>
        </div>
      </tab>

      <tab title="Share">
        <div class="mx-7 h-full">
          <template v-if="video">
            <div 
              v-for="shareitem in shareItems"
              :key="shareitem.id"
            >
              <p class="text-base font-light font-grey-darker font-sans mb-2"> {{ shareitem.title }} </p>
              <div
                class="flex justify-between mb-2 pl-4 pr-2 py-2 base-bg-darker-100 rounded cursor-pointer shareLinkWrap mb-8 overflow-x-auto"
                @click="copyText(shareitem.id)"
              >
                <p class="text-sm text-black font-sans">{{ shareitem.url }}</p>
                <input type="hidden" :id="shareitem.id" :value="shareitem.url">
                <img :src="require('../../assets/icons/copy.svg')" alt="" class="hidden">
              </div>
            </div>
          </template>
          <raditubeLogo v-else class="h-full"/>
        </div>
      </tab>

      <tab title="Screenshots" v-if="videoScreenshots.length">
        <p class="mx-7 text-base font-light font-grey-darker font-sans mb-2">Screenshots</p>
        <div 
          class="mx-7 h-full"
          :style="{ overflowY: 'auto' }"
        >
          <div 
            v-for="(screenshot, index) in videoScreenshots"
            :key="`${index}screenshot`"
            class="mt-3"
          >
            <img :src="screenshot">
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { Tabs, Tab } from '@hiendv/vue-tabs'
import { VueEditor } from "vue2-editor";
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { eventBus } from '@/main'
import { createNote } from '@/helpers/Mutations'

import { warningToastConfig } from '@/helpers/constants'
import { formatSeconds, formatTimeText } from '@/helpers/formats'
import ToastMessages from '@/data/toast_messages.json'

import openVideoOnTime from '@/components/video-detail/openVideoOnTime'
import raditubeLogo from '@/components/raditubeLogo'
import triangleInShield from '@/components/icons/triangle-in-shield'
import circlesOnCircle from '@/components/icons/circles-on-circle'
import Loader from '@/components/Loader'

export default {
  name: 'VideoDetail',
  props: ['video', 'selectOptions', 'transcription', 'timestamp', 'notes', 'users'],
  components: { 
    Tabs,
    Tab, 
    openVideoOnTime, 
    VueEditor, 
    raditubeLogo,
    triangleInShield,
    circlesOnCircle,
    Loader
  },
  data(){
    return{
      value: '',
      shareItems: [
        {
          title: 'YouTube Video URL',
          url: 'https://www.youtube.com/watch?v=',
          id: 'url'
        },
        {
          title: 'YouTube Video URL with timestamp',
          url: 'https://www.youtube.com/watch?v=',
          id: 'timestamp'
        },
        {
          title: 'RadiTube Video URL',
          url: 'https://tool.raditube.com/buckets/video/yt/',
          id: 'raditube'
        }
      ],
      noteText: '',
      noteType: 'private',
      noteTypeTeam: null,
      showUsersList: false,
      specialCharacterIndex: null,
      localUsers: [],
      activeTab: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user_meta: 'general/user_meta',
      videoScreenshots: 'videoDetails/videoScreenshots'
    }),
    teamsList(){
      return this.user_meta.teams
    }
  },
  methods:{
    ...mapActions({
      activateVideo: 'search/activateVideo',
      getDirectVideoNotes: 'videoDetails/getDirectVideoNotes'
    }),
    ...mapMutations({
      addDirectVideoNote: 'videoDetails/addDirectVideoNote'
    }),
    showVideo(time){
      this.activateVideo({
        video_id: this.video.video_id,
        timestamp: Number(time),
        show: true
      })
    },
    copyText(id) {
      let testingCodeToCopy = document.querySelector(`#${id}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      document.execCommand('copy');
      testingCodeToCopy.setAttribute('type', 'hidden');

      this.$toasted.show(ToastMessages.general.copied, warningToastConfig)
    },
    setLocalUsers(){
      this.localUsers = JSON.parse(JSON.stringify(this.users))
    },
    setShowUsersList(arg){
      this.showUsersList = arg
    },
    noteAdding(arg, oldContents){
      let itemInDelta = arg.ops.filter(elem => elem.insert)
      let retain = arg.ops.filter(elem => elem.retain)
      let remove = arg.ops.filter(elem => elem.delete)

      retain = retain[0]
      itemInDelta = itemInDelta[0]
      remove = remove[0]

      if(itemInDelta && itemInDelta.insert == '@') {
        this.setLocalUsers()
        this.setShowUsersList(true)
        if(retain) this.specialCharacterIndex = retain.retain + 1 
        else this.specialCharacterIndex = 1
      }else if(retain && retain.retain == (this.specialCharacterIndex - 1)) {
        this.setShowUsersList(false)
        this.setLocalUsers()
      }

      if(remove && remove.delete){
        let currrentContents = this.$refs.notesEditor.quill.getContents();
        let diff = currrentContents.diff(oldContents)
        diff = diff.ops.filter(elem => elem.insert)
        diff = diff[0]
        if(diff && diff.insert == '@'){
          this.specialCharacterIndex = null
          this.setShowUsersList(false)
          this.setLocalUsers()
        }
      }

      if(this.showUsersList){
        let text = this.$refs.notesEditor.quill.getText(this.specialCharacterIndex, 999)
        if(text.length > 1){
          this.localUsers = this.users.filter(elem => elem.name.toLowerCase().indexOf(text.trim().toLowerCase()) != -1)
        }else{
          this.setLocalUsers()
        }
      }
    },
    addNameToNoteText(name){
      this.$refs.notesEditor.quill.deleteText(this.specialCharacterIndex, 999);
      this.$refs.notesEditor.quill.insertText(this.specialCharacterIndex, name);
      this.$refs.notesEditor.quill.setSelection(this.specialCharacterIndex + name.length);
      
      this.showUsersList = false
    },
    setNoteType(data){
      if(data.id) {
        this.noteType = data.teamName
        this.noteTypeTeam = data.id
      }
      else {
        this.noteType = data
        this.noteTypeTeam = null
      }
    },
    async saveNote(){
      this.loading = true
      let text = this.$refs.notesEditor.quill.getText()
      text = text.trim()
      text = text.replace('\t', ' ')

      let modelObj = {
        videoId: this.video.video_id,
        noteType: this.noteType,
        name: this.user_meta.nickname || this.user_meta.name,
        description: text,
      }

      if(this.noteTypeTeam != null){
        modelObj.teamId = this.noteTypeTeam
      }


      await this.$mainClient.mutate(createNote(modelObj))


      await this.getDirectVideoNotes({ id: this.video.video_id })
      this.$refs.notesEditor.quill.deleteText(0, text.length)
      this.loading = false
    },
    close(){
      this.$emit('close')
    },
    setActiveTab(index){
      this.activeTab = index
      if(index == 2){
        let interval = setInterval(() => {
          if(this.$refs.notesEditor){
            clearInterval(interval)
            this.$refs.notesEditor.quill.root.innerHTML = `<span class="inline-block font-mono font-medium pink-bg-light leading-none rounded-sm px-2 cursor-pointer">${formatTimeText(formatSeconds(this.timestamp))}</span> </br>`
            setTimeout(() => {
              let text = this.$refs.notesEditor.quill.getText(0, 999)
              this.$refs.notesEditor.quill.setSelection(text.trim().length + 1);
            }, 0);
          }
        }, 300)
      }
    }
  },
  watch:{
    video(val){
      if(val){
        this.shareItems[0].url = `${this.shareItems[0].url}${val.video_id}` 
        this.shareItems[1].url = `${this.shareItems[1].url}${val.video_id}&t=${this.timestamp}` 
        this.shareItems[2].url = `${this.shareItems[2].url}${val.video_id}`
      }
    }
  },
  async mounted(){
    eventBus.$on('setActiveTab', this.setActiveTab)
  },
  beforeDestroy(){
    eventBus.$off('setActiveTab', this.setActiveTab)
  }
}
</script>

<style lang="scss" scoped>
.private-note,
.team-note{
  &:hover{
    &>.tooltip-text{
      display: block;
      &+.tooltip-arrow{
        display: block;
      }
    }
  }
}
.tooltipY{
  bottom: calc(100% + .75rem);
}
.team-tooltip{
  min-width: 16rem;
}
.tooltip-arrow{
  background-color: #D4D4D4;
  transform: rotate(45deg);
}
.shareLinkWrap{
  &:hover{
    img{
      display: block;
    }
  }
}
.no-notes-wrap{
  height: calc(100% - 2rem);
}
.min-w-425{
  min-width: 425px;
}
</style>